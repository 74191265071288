import Header from "./Header";
import headerLogo from "../assets/images/header-logo.png"
import secondSectionImg from "../assets/images/home-second-section.png";
import fiveSectionImg from "../assets/images/five-section.png";
import sixSectionImg from "../assets/images/six-section.png";
import sevenBanner from "../assets/images/seven-section.webp";
import nineImg from "../assets/images/nine-img.png";
import tenImg from "../assets/images/ten-section.png"
import twelveImg from "../assets/images/twelve-section.png"
import { Link } from 'react-router-dom';
import "../App.css"


function Homepage() {
    return (
        <>
            {/* _________________________hero_section____________________________ */}
            <div id="hero-section-container" className="overflow-x-hidden">
                <Header logo={headerLogo} />
                <div className="hero-section min-h-[70vh]">
                    <h1 data-aos="zoom-out" className="mt-16 lg:mt-32 text-[10vw] lg:text-[4.5vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5vw] uppercase text-center font-semibold">
                        This is a “hey u up?” text but aimed at your soul ⚡️
                    </h1>
                    <p className="mt-6 text-[4.5vw] lg:text-[1.5vw] lg:pl-[350px] lg:pr-[350px] pl-3 pr-3 text-slate-400 lg:leading-[1.8vw] text-center">
                        I have often times come to a stage which I consider to be a state of
                        enlightenment. The state when the utmost of my soul is ready to act
                        towards a complete optimal path of helping humanity. These states I
                        want more of, and seek to live a life with those who reenforce that
                        energy.{" "}
                    </p>
                </div>
            </div>
            {/* _________________________hero_section____________________________ */}
            {/* _____________________________Second-Section__________________________ */}
            <div
                id="second-section"
                className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]"
            >
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-24 pb-28 lg:pt-10">
                    <div className="flex lg:w-[41vw] w-[90vw] text-part items-center">
                        <p data-aos="fade-right" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw]">
                            I think thats the best summary of what the spiritual path is,
                            where the joy of walking your highest path raises above any short
                            term pleasures. Some I believe also call this state of mind
                            “Christ-Conciousness and the message he spoke was one which
                            attempted to shift everyone towards that devotion towards a higher
                            power, preaching a framework of eternal fulfillment or eternal
                            pain based on our actions here. And Buddha believed we will
                            reincarnate an infinite number of times until we wake up in life,
                            realize, and practice that compassion is the only thing necessary
                            to maximize and embody.
                        </p>
                    </div>

                    <div className="flex justify-center second-section-img h-auto lg:w-[40vw] w-[80vw]">
                        <img
                            data-aos="fade-left"
                            src={secondSectionImg}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>
            {/* _____________________________Second-Section__________________________ */}

            {/* __________________________Third-Section________________________________ */}
            <div className="third-section pb-20">
                <h1 data-aos="zoom-out" className="pt-16 lg:pt-32 text-[10vw] lg:text-[4vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5.5vw] text-center font-bold">
                    Nearing the Era of Infinite Potential
                </h1>
                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[350px] lg:pr-[350px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center">
                    As we reach a time when thought to reality difficulty is rapidly
                    hurtling towards 0, we do not even need these complex stories to
                    spread the same narrative of the ultimate value of becoming ones
                    highest self. Now, the story of science is clearly pointing towards
                    these being some of the latter days. It is becoming common knowledge
                    that once we achieve AGI (Human Level Ai) we will achieve the
                    capability to live as long as we want. <br /> <br />
                    And while I believe in and will speak towards the positive utopian
                    future, those who fear our annihilation should feel even more spirit
                    in reading this site as it contains keys to our capability to
                    coordinate and overcome the challenges ahead.
                </p>
            </div>

            {/* __________________________Third-Section________________________________ */}

            {/* __________________________Fourth-Section________________________________ */}
            <div data-aos="fade-up" className="fourth-section lg:ml-[100px] lg:mr-[100px] rounded-[20px]">
                <h1 className="pt-14 pb-14 text-[10vw] lg:text-[4vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5.5vw] text-center font-bold">
                    <div class="glitch-wrapper">
                        <div
                            class="glitch pl-3 pr-3"
                            data-glitch="The End As A New Beginning"
                        >
                            The End As A New Beginning
                        </div>
                    </div>
                </h1>
            </div>

            {/* __________________________Fourth-Section________________________________ */}

            {/* __________________________Five-Section________________________________ */}

            <div id="five-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div className="section-container flex gap-10 flex-wrap lg:flex-nowrap lg:justify-between justify-center pt-24 pb-24 lg:pt-28">
                    <div className="flex justify-center five-section-img h-auto lg:w-[37vw] w-[80vw]">
                        <img
                            data-aos="fade-right"
                            src={fiveSectionImg}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>

                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center">
                        <p data-aos="fade-left" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-500 lg:leading-[1.9vw]">
                            And while I believe its likely that a true utopia will include no
                            regret, and everyone will be accepting and respecting of others, I
                            do still find a sense of beauty in the fear of regret of not
                            becoming our higher selves in this grand quest.
                            <br /> <br />I enjoy connecting with the fact that my mind cannot
                            grasp absolute infinity or even our universe… but that perspective
                            it brings is worth a shot. It helps sets the stage of the scale of
                            truth within the idea that everything you do today echoes into
                            infinity. Into the universe, and into the outcome of mankind’s
                            story.
                        </p>
                    </div>
                </div>
            </div>
            {/* __________________________Five-Section________________________________ */}

            {/* __________________________six-Section________________________________ */}

            <div id="six-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-0 pb-24 lg:pt-16">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-1">
                        <p data-aos="fade-right" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-500 lg:leading-[1.9vw]">
                            You are a wave of potential within the ocean of the universe, a
                            ripple in time. If you look around and see what is happening with
                            Ai, you’ll realize this is the final wave, and thus may find the
                            strength to wave goodbye to all other desires besides compassion.
                            <br /> <br />
                            Because infinity is just impossible to comprehend…. lets instead
                            imagine a more tangible number but still massive number (which
                            when divided by infinity is still 0, such are all tangible
                            numbers).
                        </p>
                    </div>

                    <div className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-2">
                        <img
                            data-aos="fade-left"
                            src={sixSectionImg}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>
            {/* __________________________seven-Section________________________________ */}

            <div className="seven-section pb-20">
                <h1 className="pt-16 lg:pt-32 text-[10vw] lg:text-[4vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5.5vw] text-center font-bold">
                    A Million-Year Perspective
                </h1>
                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[370px] lg:pr-[370px] pl-3 pr-3 text-slate-400 lg:leading-[1.8vw] text-center">
                    Imagine you were going to spend the next 1 million years with everyone
                    you know, how would you treat them differently?
                    <br /> <br />
                    If you knew you only had a decade to be purposeful in the millions of
                    years you’ll be alive, how quickly would you lay your soul on the
                    table and try to defeat Moloch? For those who do not know, Moloch is a
                    deity which has become popular as the representation of a lack of
                    humanities capability to coordinate. For we know much evil and tyranny
                    could be stopped if we worked together more harmoniously.
                </p>

                <img
                    data-aos="zoom-in"
                    src={sevenBanner}
                    alt="description"
                    className="text-center mx-auto lg:w-2/3 w-[95%] rounded-[40px] lg:mt-20 mt-12"
                />
            </div>

            {/* __________________________Eight-Section________________________________ */}
            <div className="eight-section lg:pl-[100px] lg:pr-[100px] pb-16">
                <h1 className="pt-14 pb-14 text-[6vw] lg:text-[1.6vw] lg:pl-[150px] lg:pr-[150px] pl-3 pr-3 leading-[7.5vw] lg:leading-[1.8vw] text-center font-medium rounded-[20px] text-slate-400">

                    While the images portray a much grander idea of whats going on, it
                    should be understood that you spending your time pushing others
                    towards these ideas, act as massive butterfly effects on their
                    life, and these images better represent the true heroic act being
                    made. I find that this meme or idea of Moloch being the god of the
                    lack of our coordination (The Satan Of Rationalists), is powerful
                    because it points us in the right direction of how to oppose it.

                </h1>
            </div>

            {/* __________________________Nine-Section________________________________ */}
            <div id="nine-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div data-aos="fade-right" className="section-container flex gap-10 flex-wrap lg:flex-nowrap lg:justify-between justify-center pt-24 pb-16 lg:pt-16">
                    <div className="flex lg:w-[55vw] w-[90vw] text-part items-center order-2 lg:order-2">
                        <p className="lg:p-10 p-2 text-[5.5vw] lg:text-[2.7vw] text-slate-500 lg:leading-[3vw]">
                            As a wise man once said, understanding the problem is half the solution.
                        </p>
                    </div>

                    <div className="flex justify-center nine-section-img h-auto lg:w-[30vw] w-[80vw] order-1 lg:order-1">
                        <img
                            src={nineImg}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>


            {/* __________________________ten-Section________________________________ */}
            <div id="ten-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div data-aos="fade-right" className="section-container flex gap-10 flex-wrap lg:flex-nowrap lg:justify-between justify-center pt-16 pb-16 lg:pt-24">
                    <div className="flex lg:w-[55vw] w-[90vw] text-part items-center order-2 lg:order-2">
                        <p className="lg:p-10 p-2 text-[5.5vw] lg:text-[2.7vw] text-slate-400 lg:leading-[3vw]">
                            In this final wave, it gives rise to understanding the final boss.
                        </p>
                    </div>

                    <div className="flex justify-center ten-section-img h-auto lg:w-[30vw] w-[80vw] order-1 lg:order-1">
                        <img
                            src={tenImg}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>

            {/* __________________________eleven-Section________________________________ */}
            {/* <div id="eleven-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
        <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-0 pb-24 lg:pt-0">
          <div className="flex lg:w-[55vw] w-[90vw] text-part items-center order-2 lg:order-2">
            <p className="lg:p-10 p-2 text-[5.5vw] lg:text-[2.7vw] text-slate-400 lg:leading-[3vw]">
              It is a human alignment problem, and you are the first human you can align…..
            </p>
          </div>

          <div className="flex justify-center ten-section-img h-auto lg:w-[30vw] w-[80vw] order-1 lg:order-1">
            <img
              src={elevenImg}
              alt="Description"
              className="w-[100%] border-radius rounded-[40px]"
            />
          </div>
        </div>
      </div> */}


            {/* __________________________twelve-Section________________________________ */}
            <div id="twelve-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div data-aos="fade-right" className="section-container flex gap-10 flex-wrap lg:flex-nowrap lg:justify-between justify-center pt-16 pb-24 lg:pt-16">
                    <div className="flex lg:w-[55vw] w-[90vw] text-part items-center order-2 lg:order-2">
                        <p className="lg:p-10 p-2 text-[5.5vw] lg:text-[2.7vw] text-slate-500 lg:leading-[3vw]">
                            The world rests in your capability to help everyone in the world, coordinate and connect and if it doesn’t, it’s good and powerful to believe it does.
                        </p>
                    </div>

                    <div className="flex justify-center ten-section-img h-auto lg:w-[30vw] w-[80vw] order-1 lg:order-1">
                        <img
                            src={twelveImg}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>

            {/* __________________________last-Section________________________________ */}
            <div className="fourth-section lg:ml-[100px] lg:mr-[100px] rounded-[20px] lg:mb-[100px]">
                <h1 className="pt-14 pb-14 lg:pl-[180px] lg:pr-[180px] text-center font-bold">
                    <div class="glitch-wrapper">
                        <div
                            class="glitch"
                            data-glitch="
              Through this mindset, I seek to conduct a Spear Ritual"
                        >

                            Through this mindset, I seek to conduct a Spear Ritual
                        </div>
                    </div>


                </h1>
                <div className="next-page-btn" >
                    <Link to="/spearritual">
                        <a href="/spearritual" class="original-button">The Spear Ritual </a>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Homepage;
