import Header from "./Header";
import headerLogo from "../assets/images/black-theme-logo.png";
import "../assets/css/spearritual.css";
import sectionImg from "../assets/images/second-page-1.png";
import character from "../assets/images/character.png";
import philosophy from "../assets/images/philosophy.png";

import drunk from "../assets/images/drunk.png";
import wisdom from "../assets/images/wisdom-spear.png";
import coordination from "../assets/images/coodination.png";
import vision from "../assets/images/vision.png";
import { Link } from 'react-router-dom';

function SpearRitual() {
    return (
        <>
            {/* _________________________hero_section____________________________ */}
            <div className="second-banner">
                <Header logo={headerLogo} />

                <div className="hero-section min-h-[70vh]">
                    <h1 className="lg:pb-8 mt-16 lg:mt-16 text-[10vw] lg:text-[4.5vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5vw] text-center font-bold">
                        Launching the Spear Ritual
                    </h1>
                    <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[350px] lg:pr-[350px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-16">
                        I know of many insanely spiritual people, but I know of few who
                        truly embody the great knowledge of this being most important decade
                        in human history who seek to come together with a plan. I see a
                        direct path for us to effect saving millions of lives by pushing
                        humanity forward, and for that reason, I must try.
                        <br />
                        <br />
                        Thus, this is titled the Spear Ritual because it every great
                        movement starts with a tipping point. What would actually be the best outcome? What things could we coordinate and do, if we actually cared enough?
                        These are answers the world desperately needs as we hurtle into the future.
                    </p>
                </div>
            </div>

            {/* ---------------------------------------------Second-Section----------------------------- */}
            <div
                id="second-section"
                className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]"
            >
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-24 pb-16 lg:pt-24">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-1">
                        <p data-aos="fade-right" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw]">
                            Theres literally never been a better time to dream big about the
                            worlds potential and to wake up more of the world to that. To do
                            so is to become a piece of the spear, and the act of playing and
                            performing it is a ritual of leading a great story in humanities
                            waking process.
                            <br />
                            <br />
                            Avoid hippie failure mode, dont wait, play the game and wake the
                            world up. #EffectiveBuddhism
                        </p>
                    </div>

                    <div data-aos="fade-left" className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-2">
                        <img
                            src={sectionImg}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>

            {/* ---------------------------------------------Third-Section----------------------------- */}
            <div id="third-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-0 pb-24 lg:pt-16">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-2">
                        <p data-aos="fade-left" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw]">
                            We’ve all seen the hero’s journey in the movies, and it’s
                            important to recognize we are just like characters in those
                            movies, residing at the most meaningful time to be alive in human
                            history.
                            <br />
                            <br />
                            Our shared reality seems to beckon for play, and heroic conquest.
                            I for one am grateful for this shot at really making a tangible
                            impact in our planet during this time.
                        </p>
                    </div>

                    <div className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-1">
                        <img
                            data-aos="fade-right"
                            src={character}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>

            {/* -----------------------------------------Forth-Section----------------------------- */}
            <div
                id="spear-fourth-section"
                className="lg:ml-[100px] lg:mr-[100px] rounded-[20px] mt-16 lg:pl-[100px] lg:pr-[100px]"
            >
                <h1 className="lg:pb-8 mt-16 lg:mt-16 text-[10vw] lg:text-[4.5vw] lg:pl-[100px] lg:pr-[100px] pl-3 pr-3 leading-[12vw] lg:leading-[5vw] text-center font-bold">
                    A New Ritual for an Ancient Quest
                </h1>

                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[50px] lg:pr-[50px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-12">
                    Spirituality can be viewed as a journey towards profound wisdom. This
                    wisdom emerges from our connection with what some call the divine,
                    enlightening us not only with a vision for the world but also with the
                    understanding of concepts that guide us towards realizing it.
                    <br />
                    <br />
                    Thus spiritually has never been made to be held onto, but to be shared
                    into reality. Enlightenment is a spectrum whose depths have been
                    achieved internally by many, but the real bottleneck is for these
                    visions, and wisdom to reach more people.
                    <br />
                    <br />
                    <b className="text-slate-600 lg:text-[2vw]">
                        “Enlightenment is already here, it’s just not evenly distributed
                        yet”
                    </b>
                    <br />
                    <br />
                    The spear ritual embodies the spiritual journey, embracing profound
                    concepts similar to those explored by Buddha, and seeking pathways of
                    facilitating this wisdom into our collective consciousness. Buddha
                    ignited a transformative movement without the aid of modern
                    technology, now, narratives has the potential to disseminate globally
                    at an unprecedented pace, mirroring the speed of light itself.
                    <br />
                    <br />
                    My friend Jacob Cole, helped me recognize the capability to bring
                    these dreams to life, your being and place in the world is what brings
                    me here now.
                    <br />
                    <br />
                    Burning Man also allowed me to tap into understanding this future
                    culture, it’s a place where people stop moving their goalposts and
                    become in tune with community. It’s a place which embodies the idea of
                    enlightened capitalism where what you give is more valuable than what
                    you get. It’s really a magical place, that helps show you how great,
                    interesting, and fun the future of humanity is going to be!
                    <br />
                    <br />
                    Yet the spear ritual is occurring in the present, and in order to
                    understand what to do next, we need to start with where we are going,
                    and work backwards from there. We need to start with WHY, not just any
                    why, but the greatest why, and to do that we need PHILOSOPHY.

                    <br />
                    <br />
                    <i>PHILOSOPHY is the science of estimating values. The superiority of
                        any state or substance over another is determined by philosophy.
                        By assigning a position of primary importance to what remains when
                        all that is secondary has been removed, philosophy thus becomes
                        the true index of priority or emphasis in the realm of speculative
                        thought. The mission of philosophy a priori is to establish the
                        relation of manifested things to their invisible ultimate cause or
                        nature. </i>
                </p>

                <img
                    data-aos="zoom-in"
                    src={philosophy}
                    alt="philosophy"
                    className="w-[100%] rounded mb-16"
                />
            </div>

            {/* ----------------------------Sixth_section----------------------------- */}
            <div id="sixth-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-24 pb-16 lg:pt-24">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-1">
                        <p data-aos="fade-right" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-500 lg:leading-[1.9vw] text-left lg:text-center">

                            In this vast expanse where our thoughts ignite,        <br />
                            Getting drunk on Philosophy… the *high* of the light.        <br />
                            It's not just words, it's wisdom's own site,        <br />
                            The soul’s guide through day and through night.        <br />
                            <br />
                            Great masters historically riffed,<br />

                            dropping quotes like hot tracks,<br />

                            Cicero, Hobbes, Jesus, setting wisdom on max.<br />
                            Seeking the vibe, <br />

                            that truth-filled feedback,<br />
                            They tuned into life,<br />

                            never looking back.
                        </p>
                    </div>

                    <div className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-2">
                        <img
                            data-aos="fade-left"
                            src={drunk}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>

            {/* ----------------------------Seven_section----------------------------- */}
            <div id="seven-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-0 pb-24 lg:pt-16">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-2">
                        <p data-aos="fade-left" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-500 lg:leading-[1.9vw] text-left lg:text-center">
                            They pushed higher, with common sense as the beginner stage, YOU can aim for the stars, be the sage of the age.
                            <br />
                            <br />
                            Those who aim high, are in history’s page,<br />
                            Dancing with the cosmos, molding the cage.<br />
                            <br />
                            <br />
                            Cicero jammed, with wisdom's sweet trance,<br />
                            Descartes gave positive thinking an eternal glance. From simple vibes, to deep-rooted plans,<br />
                            They rode deep beats, in our planets dance.
                            <br />
                            <br />
                            In the historic stories of gods, in its many ways,<br />
                            Philosophy has been the DJ, always setting the blaze.<br />
                        </p>
                    </div>

                    <div className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-1">
                        <img
                            data-aos="fade-right"
                            src={wisdom}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>

            {/* -----------------------------------------Eight_section------------------------------ */}

            <div className="seven-section pb-20">
                <h1 className="pt-16 lg:pt-32 text-[10vw] lg:text-[4vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5.5vw] text-center font-bold">
                    From Ideas to Action
                </h1>
                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[370px] lg:pr-[370px] pl-3 pr-3 text-slate-400 lg:leading-[1.8vw] text-center">

                    Jacob and I are both killers at spawning real ideas that are actually aimed at systemic change to the world, this is our internal hack.

                    To build “Prime Programs” like in the Matrix which wakes the world up. Together, I believe we can execute all of them.
                    <br /> <br />
                    How crazy is it today that no specific “How We Save The World” team or book has emerged in the world?

                    Or rather, where is the populist movement, or youtubers of an expansive positive global narrative? It seems like no one is really trying, no one is even finding those who dream and hope and acting as a unifying force.

                    We all can reasonably understand that with great coordination we could solve all our problems, but what about when we actually dive into what that means for the world?
                </p>

                <img
                    data-aos="zoom-out"
                    src={coordination}
                    alt="description"
                    className="text-center mx-auto lg:w-2/3 w-[95%] rounded-[40px] lg:mt-20 mt-12"
                />
            </div>


            {/* -----------------------------------------nine_section------------------------------ */}

            <div className="nine-section pb-20">
                <h1 className="pt-16 lg:pt-24 text-[10vw] lg:text-[4vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5.5vw] text-center font-bold">
                    The Power of Positive Narratives for Hope
                </h1>
                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[370px] lg:pr-[370px] pl-3 pr-3 text-slate-400 lg:leading-[1.8vw] text-center">

                    Many are becoming existentially accelerated witnessing the old world break down.

                    WHEN ONE FEELS CONNECTED FOR THE CAPABILITY FOR ANSWERS THAT EXIST TO BECOME CARED ABOUT, THE SELF CARES MORE, AND SEE THE WORLD CAN AND WILL TOO.
                    <br />
                    <br />
                    Staying connected to that narrative, and any of those positive narratives give us something to attach ourselves to which gives us hope. Today, with rising existential acceleration, and no “team good” people are being pushed into a sense of being lost, their existential acceleration lacks a place for it to flow positively into.
                    <br />
                    <br />
                    This is why the great narrative of even the potential for coordination is the key to existential hope. For anyone who has the keys of coordination, would instead see their own existential acceleration, and the worlds, as a gift and opportunity for us to come together and make the path of bloom happen.
                    <br />
                    <br />
                    So what is this narrative we keep alluding to?<br />

                    Lets dive in…<br />

                    We’ll stay zooming way out (and then get down into the meat and potatoes).<br />
                    <br />

                    This is not a vision just outlining problems, but an opening sequence and onboarding to a team with a systematic plan of action for bringing systemic solutions deterministically forward in time.
                </p>

                <img
                    data-aos="zoom-out"
                    src={vision}
                    alt="description"
                    className="text-center mx-auto lg:w-2/3 w-[95%] rounded-[40px] lg:mt-20 mt-12"
                />
            </div>

            {/* __________________________last-Section________________________________ */}
            <div className="fourth-section lg:ml-[100px] lg:mr-[100px] lg:mt-[100px] mt-14 lg:mb-[100px] rounded-[20px]">
                <h1 className="pt-14 pb-14 lg:pl-[180px] lg:pr-[180px] text-center font-bold">
                    <div class="glitch-wrapper">
                        <div
                            class="glitch"
                            data-glitch="
                            A Collective's Strategic Onboarding to Impactful Change"
                        >

                            A Collective's Strategic Onboarding to Impactful Change
                        </div>
                    </div>


                </h1>
                <div className="next-page-btn" >
                    <Link to="/basedreality">
                        <a href="/basedreality" class="original-button">Enter Based Reality </a>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default SpearRitual;
