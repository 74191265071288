import Header from "./Header";
import headerLogo from "../assets/images/black-theme-logo.png";
import "../assets/css/basedreality.css";
import earth from "../assets/images/Earth.png";
import abundance from "../assets/images/abundance.png";
import wildest from "../assets/images/wildest.png";
import together from "../assets/images/together.png";
import youtube from "../assets/images/youtube.png";
import commanders from "../assets/images/commanders.gif";
import { Link } from "react-router-dom";
import { useEffect, useRef } from 'react';
import gsap from 'gsap';


function BasedReality() {
    const textRef = useRef();

    useEffect(() => {
        gsap.from(textRef.current, {
            scale: 0, // Start from scale 0 (invisible)
            autoAlpha: 0, // Use autoAlpha for handling both CSS visibility property and opacity
            duration: 1.5, // Animation duration of 1.5 seconds
            ease: "back.out(1.7)", // Use a back out easing for a slight overshoot effect
        });
    }, []);

    return (
        <>
            {/* _________________________hero_section____________________________ */}
            <div className="second-banner">
                <Header logo={headerLogo} />

                <div className="hero-section min-h-[70vh]">
                    <h1 className="lg:pb-8 mt-16 lg:mt-16 text-[10vw] lg:text-[4.5vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5vw] text-center font-bold mx-auto">
                        Enter Based Reality
                    </h1>
                    <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[350px] lg:pr-[350px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-16">
                        Let’s bring our vision from the macro to the micro. From celestial
                        presence to the present.
                        <br />
                        <b>Universal Level Vision: </b>We are here to wake up the universe
                        and bring the heavens down to earth, to enjoy humanity flourishing
                        for billions or trillions of years. We will seed new planets, and
                        bring new beings into the higher vibe we are gaining access to
                        through our actions today.
                        <br />
                        <br />
                        <b>Cosmic Level Vision: </b>
                        We are here to create limitless abundance with every human soul
                        cared for and brought up maslow’s hierarchy of needs.
                    </p>
                </div>
            </div>

            {/* ---------------------------------------------Second-Section----------------------------- */}
            <div
                id="second-section"
                className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]"
            >
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-24 pb-16 lg:pt-24">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-1">
                        <p data-aos="fade-right" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw]">
                            <b>Earth Level Vision: </b> WE THE PEOPLE, are here to defeat
                            corruption by giving the power of humanity back to humanity
                            itself, bring forth an era of understanding that the only eternal
                            currency is good karma, which will align the worlds manifestations
                            around the universal macro.
                            <br />
                            <br />
                            We live in a world where man is extracted upon, from the
                            old-school short sighted politics, unenlightened greed, and a lack
                            of compassion for others. Our world is struck by a mafia
                            federation that controls the money supply, looting from the
                            general public.
                        </p>
                    </div>

                    <div className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-2">
                        <img
                            data-aos="fade-left"
                            src={earth}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>

                <p className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw] mt-0">
                    This video does a great job of showcasing it
                </p>
                <div
                    className="relative w-full overflow-hidden mb-10"
                    style={{ paddingTop: "56.25%" }}
                >
                    <iframe
                        data-aos="zoom-in"
                        className="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/Z9HbQsTgrtk?si=gnxLCw7M9uEUEQQN"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>

            {/* ---------------------------------------------Third-Section----------------------------- */}
            <div id="third-section" className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]">
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-0 pb-24 lg:pt-16">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-2">
                        <p data-aos="fade-left" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw]">
                            If abundance will be reached, and that benefits all, then the only
                            true way to win the whole time was to fight for this winning team!
                            There is no power in holding power over others, when it will
                            inevitability be taken away. More of the powers that be are
                            recognizing this, and as they do they are FIGHTING to do more good
                            in the world.
                            <br />
                            <br />
                            Enlightened capitalism is inevitable, it just hasn’t reached mass
                            market yet.
                            <br />
                            <br />
                            Given that globalization is inevitable, we want to make sure it
                            proceeds a non-corrupt, non-totalitarian future for mankind. “WE
                            THE PEOPLE” have always historically won against tyranical rule,
                            and it will happen again… this time on a global scale.
                            <br />
                            <br />
                            The movement that truly gains traction that is BY THE PEOPLE and
                            FOR THE PEOPLE will help realize the capability for global
                            superpowers to have to adhere to decentralization and
                            transparency, which will ultimately DEFEAT corruption.
                        </p>
                    </div>

                    <div className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-1">
                        <img
                            data-aos="fade-right"
                            src={abundance}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>
            </div>

            {/* -----------------------------------------Forth-Section----------------------------- */}
            <div
                id="spear-fourth-section"
                className="lg:ml-[100px] lg:mr-[100px] rounded-[20px] mt-16 mb-16 lg:pl-[100px] lg:pr-[100px]"
            >
                <h1 className="lg:pb-8 mt-16 lg:mt-16 text-[10vw] lg:text-[4.5vw] lg:pl-[100px] lg:pr-[100px] pl-3 pr-3 leading-[12vw] lg:leading-[5vw] text-center font-bold">
                    Continuing The Pattern Towards Greatness:
                </h1>

                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[50px] lg:pr-[50px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-12">
                    America as a nation pushed forward a better democracy. This left the
                    monarchy system and replace it with a representative democracy that
                    was the most decentralized or “liquid” that was possible at the time.
                    <br />
                    <br />
                    Now, in the age of the internet, the ideal laid out in the
                    constitution of all souls being valued equally can finally come to
                    light. When this prior constitution for a more perfect union was made,
                    the speed information traveled in that union was at the speed of a
                    horse or carrier pigeon. Now we have the speed of light at our
                    fingertips.
                    <br />
                    <br />
                    That means rather than needing to create a political class, we can
                    truly have a liquid political class. That means any person can take
                    part in voting, or elect any person at any time to have the authority
                    of their vote if they are open to it.
                    <br />
                    <br />
                    As we enter this era of post-scarcity, life can be a dream we can
                    dream together. The earth will be able to be divided equally to us all
                    if we wish to stay awhile.
                    <br />
                    <br />
                    That means 500k Sq Ft of human land with ocean front and hundreds of
                    levels down may every man have at the end-game. If we wish to allow
                    this re-construction to occur we can all go to the moon in the
                    meantime. We will throw a party on the moon, with a tentative date of
                    4/20/2042 during the full lunar eclipse as a celebration of this grand
                    vision coming to fruition.
                    <br />
                    <br />
                    Staring at the earth, as our wildest dreams are allowed to come true
                    before our eyes.
                    <br />
                    <br />
                </p>

                <img
                    data-aos="zoom-in"
                    src={wildest}
                    alt="philosophy"
                    className="w-[100%] rounded mb-16"
                />

                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[50px] lg:pr-[50px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-12">
                    <b className="text-slate-600 lg:text-[2vw]">
                        From Vision To Execution:
                    </b>
                    <br />
                    <br />
                    How we hack the macro vision beyond zero to one…. from 0 To Hero. Who
                    is the 0 doing the moving to hero? You and I. And all together we are
                    ∞
                </p>

                <img
                    data-aos="zoom-in"
                    src={together}
                    alt="philosophy"
                    className="w-[100%] rounded mb-16"
                />
            </div>

            {/* -----------------------------------------fifth_section------------------------------ */}

            <div className="seven-section pb-10">

                <iframe
                    title="sound-song"
                    className="w-[90%] lg:w-[60%] mx-auto mt-36"
                    style={{ height: "166px", scrolling: "no", frameBorder: "no", allow: "autoplay" }}
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/164138555&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                ></iframe>
                <div
                    style={{
                        fontSize: "10px",
                        color: "#cccccc",
                        lineBreak: "anywhere",
                        wordBreak: "normal",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontFamily: "Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif",
                        fontWeight: "100"
                    }}
                >


                </div>


                <h1 className="pt-16 lg:pt-16 text-[10vw] lg:text-[4vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5.5vw] text-center font-bold">
                    From YouTube to Global Dominion
                </h1>
                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[370px] lg:pr-[370px] pl-3 pr-3 text-slate-400 lg:leading-[1.8vw] text-center">
                    Ok enough with the bullshit fluff, sometimes I cant help myself.
                    <br />
                    <br />
                    Going to zero to hero and going Zero To One like mentioned in Peter Thiel’s book has many similarities. For example, it talked about how the best companies look big, and act small.
                    <br />
                    <br />

                    That means they have direct customer relationships with great service, but look authoritative and official. If we expand these things to their extremes for the Zero To Hero sense the epitome of look big act small is scaling a memetic world order from a youtube channel.
                </p>

                <img
                    data-aos="zoom-in"
                    src={youtube}
                    alt="description"
                    className="text-center mx-auto lg:w-2/3 w-[95%] rounded-[40px] lg:mt-20 mt-12"
                />
            </div>

            {/* -----------------------------------------six_section------------------------------ */}

            <div className="nine-section pb-20 lg:pb-32">
                <h1 className="pt-16 lg:pt-24 text-[10vw] lg:text-[4vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5.5vw] text-center font-bold">
                    Commanding the Future
                </h1>
                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[370px] lg:pr-[370px] pl-3 pr-3 text-slate-400 lg:leading-[1.8vw] text-center">
                    Its time to take hold as commanders of the <b className="text-white"><a href="https://dia.wiki/">Decentralized Intelligence Agency</a></b>, Secrete Service to the world, and collectively manage the <b className="text-white"><a href="https://federalpreserve.org/">Federal Preserve.</a></b>
                    <br />
                    <br />
                    I don’t think there will ever be a time when playing and having fun telling the story of being hero’s would be “wrong”. And anyone who claims to be a hero who isn’t having fun doing it clearly does not believe enough in their plan or where it leads.
                    <br />
                    <br />
                    The goal is to play towards the divine. The universe wills us to play as heroes. To tell the story we know can be told of humanity rising to the occasion.
                    <br />
                    <br />
                    This next bit is the blueprint of the moonshot before us.
                </p>

                <img
                    data-aos="zoom-in"
                    src={commanders}
                    alt="description"
                    className="text-center mx-auto lg:w-2/3 w-[95%] rounded-[40px] lg:mt-20 mt-12"
                />
            </div >

            {/* _________________________seven-Section________________________________ */}
            < div className="fourth-section lg:ml-[100px] lg:mr-[100px] lg:mt-[100px] mt-14 lg:mb-[100px] rounded-[20px]" >
                <h1 className="pt-14 pb-14 lg:pl-[180px] lg:pr-[180px] text-center font-bold">
                    <div class="glitch-wrapper">
                        <div
                            class="glitch"
                            data-glitch="
                            A Mission to Serve the World with Joy"
                        >
                            A Mission to Serve the World with Joy
                        </div>
                    </div>
                </h1 >
                <div className="next-page-btn">
                    <Link to="/whitepill">
                        <a href="/whitepill" class="original-button">
                            The Main Quest | Spread The Greatest Whitepill
                        </a>
                    </Link>
                </div>
            </div >
        </>
    );
}

export default BasedReality;
