import React from 'react';
import "../assets/css/accordion.css"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default function Example() {
    return (
        <Accordion>
            <AccordionItem className='accordion-div'>
                <AccordionItemHeading className='accordion-heading text-[4.5vw] lg:text-[1.3vw] text-slate-800 lg:leading-[1.9vw]'>
                    <AccordionItemButton>
                        <b>MOVIE:</b> VISION QUEST | The White Mirror, A Meta Story Of How Humanity Wins
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className='text-[3.8vw] lg:text-[1.1vw] text-slate-800 lg:leading-[1.7vw]'>
                        Ai Video has the potential to create a Wall-E world, or a world of enlightenment. It is either the ultimate reign of Moloch, or the ultimate coordination function. There is a small window of time before this technology is so democratized that we reach movie overload. During this cultural transition, the first few Ai films we be their own viral sensation, setting a cultural tone like the best movies of the past have done so. Except, because its a leap into a new category this has the potential to do so on an unprecedented scale.
                        <br />
                        <br />
                        Therefore it marks a capability to turn set into motion a positive shift in mindsets, and a platform of technologies and ideas that will help humanity blossom.
                        <br />
                        <br />
                        I am going to write to the best of my ability what that plot may look like, and what certain things would have the highest potential positive impact to seed in terms of change.
                        <br />
                        <br />
                        I like the name “The Bloomsday Preppers” as it sets the precedent of the story on a group of people, who help platform humanities great potential.
                        <br />
                        <br />
                        Within every count of p(doom) there is an opposing p(bloom). This story of the bloomsday preppers creates a self-fulfilling prophecy of what it takes to get there, and helps show people the mechanisms that will increase p(bloom).
                        <br />
                        <br />
                        The projects they bring to life in this story, will platform the upbringing of these projects in the real world. The movie will showcase their ultimate progression in building these projects and how they positively impact the world at large. These projects, include the rest of these in the list of social growth and the projects category on Purplerock. The final project they will work on is putting together the very movie that people are watching. It is “meta” in that sense. Breaking the 4th wall.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='accordion-div'>
                <AccordionItemHeading className='accordion-heading text-[4.5vw] lg:text-[1.3vw] text-slate-800 lg:leading-[1.9vw]'>
                    <AccordionItemButton>
                        <b>Book:</b> TANGIBLE | Hand To Hand Combat In This Spiritual War
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className='text-[3.8vw] lg:text-[1.1vw] text-slate-800 lg:leading-[1.7vw]'>
                        A book called:
                        <br />
                        <br />
                        “Give this to every human you know”
                        <br />
                        <br />
                        (The coordination meme)
                        <br />
                        <br />
                        No book thus far has been given to me to make me excited about that, the only thing that has, has been private conversations on topics I will write in here. This writing is here to expand your mind on the capabilities that any human can manage.
                        <br />
                        <br />
                        This writing is an attempt to create the coordination plan. The fact of the matter is that we are approaching an era where everyone finally wakes up to the need to CARE. And all that we need is to be prepared with an answer of what we can do. Basically building the railway system in the minds of people of what can be done when it needs to get done. Right now, many are in this state where they would share the right thing with their entire contact sheet!
                        <br />
                        <br />
                        An anti-mlm hit piece does a great job of showing the actual feasibility of how every person coming into contact with someone sharing it with 5 people would reach the entire world in 15 steps.
                        ”Hypothetically speaking, if you were placed at the top of an MLM and were told to recruit 5 individuals for your downline, they would be expected to recruit an additional 5 each for their own downlines, and so on so forth. After 15 cycles of this recruitment process, you would run out of people to recruit on the entire planet, causing the entire business to collapse as a result. Moreover, MLMs have been criticised for their unethical practices and “cult-like” mentality.” - [Centuria](https://www.centuria-sa.org/post/multi-level-mockery-the-predatory-networks-of-mlms#:~:text=After%2015%20cycles%20of%20this,%E2%80%9Ccult%2Dlike%E2%80%9D%20mentality.)
                        <br />
                        <br />
                        Imagining a world where an average of 5 people get shared the most important piece of coordination text in existence is not hard, especially if you feel or forsee the exstential accelerants that Ai will bring causing people like myself to share it with thousands! Now it may be another thing getting that many people to sit down right then and read it, but a group of rational people who truly have the goal to growth hack a message to the world, truly have a capability to. The story alone of doing so, of building a movement with answers and platforms to solve humanities biggest problems is such a big gap in the market.
                        <br />
                        <br />
                        One such mechanism is turning the writing into a tangible piece of art.
                        <br />
                        <br />
                        Paperback books have this weird sense of being more likely to be read. Not only do they feel more meaningful as a gift, but they do not compete for attention on the attention hacking machine. They offer access to a super-modern vibe, in a weirdly older context of reading (at least for the youth).
                        <br />
                        <br />
                        Luckily modern infrastructure allows even US based direct printing to cost only a few dollars. This text is worthy of not only being given for free, but of its existence making a statement on the world at least getting them to read it!
                        <br />
                        <br />
                        For all those who have **$17.76** to spare spawn 5 more books, to be put into the hands of the ones with extra time, who may enjoy door knocking like a missionary spreading the message further. This process alone, of handing out a book on how to save the world is viral.
                        We are in an age of union, glimpsing the comet of Ai, experiencing lack of trust in government, when such a book with actual answers would have memetic spread across the world. Culture will get mankind to read, person to person sharing knowledge of divine connection. The populist movement may rise from a simple book.
                        <br />
                        <br />
                        Akin to Social Nakamoto releasing Bitcoin, but instead of being aimed at a populace who lacks the philosophical grounding to understand its depth and need, instead one aimed at a universal basis of thought and understanding.
                        <br />
                        <br />
                        Let it be known that this book is an attempt at that, let it also be known that this book as with all great ideas, they involve. Instead, this may be similar to the story of Hashcash, a predecessor of Bitcoin, which Bitcoin used the code and idea of to build on top of.
                        <br />
                        <br />
                        I write this in here to make you know that either way, you are reading a book of an attempt at being the most important book in the world. That means at the very least you will learn things.
                        <br />
                        <br />
                        The goal is to craft memetic meaning of life, or at least of a universal one… The fact that this is the first time you have even seen it tried, SAYS SOMETHING. I say that because even talking about this idea I never met anyone who had seen someone try. The closest reference was always the old texts pre-dating the internet, most recently Joseph Smith and the Church of Mormon.
                        <br />
                        <br />
                        The only lack of aiming higher for many is the belief that they can get there. The conviction that if they go down that road that it will lead to the goals success. Thats why I’ve focused on otherworldly hacks. Ideas that are so compelling, they only require execution to see great wakes. Ideas that give one belief in a plan becoming viral, and important.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className='accordion-div'>
                <AccordionItemHeading className='accordion-heading text-[4.5vw] lg:text-[1.3vw] text-slate-800 lg:leading-[1.9vw]'>
                    <AccordionItemButton>
                        <b>APP:</b> NETWORK HACK | Get Infected By The Prodemic Today! The Viral Awakening Meme
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p className='text-[3.8vw] lg:text-[1.1vw] text-slate-800 lg:leading-[1.7vw]'>
                        Infections are typically seen as bad, but this one has been engineered to be an upgrade to your programming. You see, all the information you hold is like the programming inside of you. The best people in the world, simply run the best programs.  Thats why we call it prodemic, because it represents the inevitable, much so needed pandemic of unity.
                        <br />
                        <br />
                        Rather than this pandemic attacking your immune system every word is reprogramming your mental system!
                        <br />
                        <br />
                        All information is a meme that does this actually!
                        <br />
                        <br />
                        A meme is an idea, behavior, or style that spreads by means of imitation from person to person within a culture and often carries symbolic meaning representing a particular phenomenon or theme.
                        <br />
                        <br />
                        Memes run the world!
                        <br />
                        <br />
                        All actions are based on memes. Which in other words simply means that all actions are taken based on the output they’ll procure in the world. Some actions, like taking our hand off a hot stove is a biological meme, coded into your system by an evolutionary process.
                        <br />
                        <br />
                        On this same degree of thought, every action anyone has taken since the dawn of man has been a manipulation on the world.
                        <br />
                        <br />
                        Words are like spells, and when we speak or act we are SPELLING.
                        <br />
                        <br />
                        So asking what the greatest spell is is basically asking what the greatest meme in the world is.
                        <br />
                        <br />
                        Obviously there an objective answer. But there is a subjective answer, and the Prodemic is an agreement between you and the universe, to write the best one you can and share it with everyone you know. And this one you are reading now has had hundreds of the smartest minds in the world, worth hundreds of billions of dollars, partake in creating it.
                        <br />
                        <br />
                        I mean, given the butterfly effect, every human has shaped a piece of this, but of course I mean on a direct visceral level of their speech or thought.
                        <br />
                        <br />
                        [Here I could list names of people influenced by]
                        <br />
                        <br />
                        What is information so good that if the whole world read it, our timeline as a human tribe on a floating rock in space would have the best possible outcome?
                        <br />
                        <br />
                        Well actually, we can make this simpler, what is information so good that someone would download an app in order to mass text it to their entire contacts list? Information so good it compels 3 thumb clicks from someone?
                        <br />
                        <br />
                        I wanted to understand how powerful this is, so I first wanted to know the average number of cellphone contacts in someones phone:
                        <br />
                        <br />
                        From Umar Ashfaq:
                        ”I, with a team of few amazing developers, have developed a contacts backup app for all major smartphone platforms including Windows Phone, Android and iPhone. The app has been downloaded more than 335k times and has been used to export more than 276 million contacts.
                        <br />
                        <br />
                        Although we are highly sensitive about user privacy, we do keep a record of total contacts exported per request anonymously for basic stats. Based upon our database, a person on average has 654 contacts in their phone book.”
                        <br />
                        <br />
                        Obviously its extremely difficult to get the world to download an app and send a document, but I believe theres a video that would encapsulate this message, and would be a viral trend. If we all wore a mask to make the world better, can’t we all share an important message? The pandemic was a global phenomena that touched every human heart, so the Prodemic branding would relate to the entire world.
                        <br />
                        <br />
                        The numbers are literally crazy at how fast something could spread.
                        <br />
                        <br />
                        If each person shares the meme with 611 people, but there's overlap, let's make a conservative estimate that only half of those shares reach new people. So, effectively, each person shares the meme with about 305.5 new people.
                        <br />
                        <br />
                        1. **Round 1:** 611 people   <br />

                        2. **Round 2:** 611×305.5=186,655.5, let's round to 186,655


                        611×305.5=186,655.5
                        <br />

                        3. **Round 3:** 186,655×305.5≈57,048,052.5, round to 57,048,052


                        186,655×305.5≈57,048,052.5   <br />

                        4. **Round 4:** 57,048,052×305.5≈17,431,983,886, which is more than the world's population.

                        57,048,052×305.5≈17,431,983,886   <br />   <br />


                        So, by this very rough estimate, it would take about 4 rounds of sharing for the meme to reach the entire world's population, assuming each person effectively shares it with about half of the 611 people they initially tried to share with.
                        <br />   <br />
                        [This could actually be an app that checks all of the contacts, cross-references them with all contacts that exist, and tells you which of your friends have not been infected so you can be the first I could imagine good enough UX having a truly viral experience, especially if paired up with a major news event that drives existential fear.]
                        <br />   <br />
                        By getting infected by the Prodemic you enter a social contract with who sent it to you to not let it die. [The App Could Have A Registry Of Proof] That does not mean sharing my writing, although you have full permission to copy it, but spending an hour to draw out the greatest information within you and gifting that to your contacts list.
                        <br />   <br />
                        Its a social contract because this is such an important line of thinking for relationships.
                        <br />   <br />
                        Our relationships should always be the connectors of each other towards our highest selves.
                        <br />   <br />
                        Your lower self wants you to think you have to be busy with all this other shit, but at the end of the day what really matters? Its your relationships!
                        <br />   <br />
                        The crazy thing to recognize is that you will live with entirely different versions of the people you interact with most. You are a butterfly effect that changes them, their experiences, and how they view the world.
                        <br />   <br />
                        The fact that we now have the power to connect information to our entire connect and have never put together the most important things we can and share it with the ones we love is a complete missing revolution of our culture.
                        <br />   <br />
                        I believe if we simply share the best information we can with each other, it would represent a vibe shift that could literally save the world. Especially as a marker of hope of our ability to coordinate, and have viral challenges like ALS ice bucket challenge which has ever more depth.
                        <br />   <br />
                        The idea of how fast we can spread information is in my opinion extremely impressive.
                        <br />   <br />
                        Watch this video, and recognize how we should all hold the mic. That we need tools that give us all a voice. Its the pattern of revolution and revolt against tyranny and control all over again, except this time its for a one sole government, or a <a href='https://soulgovernment.com/' className='underline'>government made of all our souls.</a>

                    </p>
                    <iframe className='w-[100%] h-[70vw] lg:h-[40vw] p-10' src="https://www.youtube.com/embed/w21D4LFnOgw?si=vbDYNvTGnYkOWwQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <p className='text-[3.8vw] lg:text-[1.1vw] text-slate-800 lg:leading-[1.7vw]'>
                        <a href='https://prodemic.org/' className='underline'>Https://Prodemic.org </a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion >
    );
}