import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import Homepage from "./components/Homepage";
import SpearRitual from './components/SpearRitual';
import BasedReality from './components/BasedReality';
import Whitepill from './components/WhitePill';
import ScrollToTop from './components/ScrollToTop';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';


function App() {
  useEffect(() => {
    AOS.init({
      // You can also pass settings
      duration: 1200 // controls the animation duration
    });
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/spearritual" element={<SpearRitual />} />
        <Route path='/basedreality' element={<BasedReality />} />
        <Route path='/whitepill' element={<Whitepill />} />

      </Routes>
    </BrowserRouter>

  );
}

export default App;
