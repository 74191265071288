import Header from "./Header";
import headerLogo from "../assets/images/black-theme-logo.png";
import "../assets/css/whitepill.css";

import whitepill from "../assets/images/white-pill.png";

import Example from './Accordion';

import white1 from "../assets/images/white-1.png";
import video1 from '../assets/images/white-video-1.mov';
import video2 from '../assets/images/white-videe-2.mov'
import white2 from "../assets/images/white-2.jpg";
import white3 from "../assets/images/white-3.jpg";
import white4 from "../assets/images/white-4.png";
import white5 from "../assets/images/white-5.jpg";
import white6 from "../assets/images/white-6.jpg";
import white7 from "../assets/images/white-7.png";
import white8 from "../assets/images/white-8.png";
import white9 from "../assets/images/white-9.png";
import white10 from "../assets/images/white-10.gif";
import white11 from "../assets/images/white-11.png";

function Whitepill() {




    return (
        <>
            {/* _________________________hero_section____________________________ */}
            <div className="second-banner">
                <Header logo={headerLogo} />

                <div className="hero-section min-h-[70vh]">
                    <h1 className="lg:pb-8 mt-16 lg:mt-16 text-[10vw] lg:text-[4.5vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5vw] text-center font-bold mx-auto">
                        The Greatest Whitepill
                    </h1>
                    <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[350px] lg:pr-[350px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-16">
                        The main quest of the life I want to live is daring is to let maximizing your vibes / having fun with it being the answer. To spread a whitepill to the entire world.
                        <br />
                        <br />
                        It is my main quest because it has solved the idea of “What is the meaning of life?” by recognizing that compassion and acting to serve the world are the best vibes, and that doing that publicly is the means for it to push towards key systemic change.
                    </p>
                </div>
            </div>


            {/* ---------------------------------------------Second-Section----------------------------- */}
            <div
                id="second-section"
                className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]"
            >
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-24 pb-16 lg:pt-24">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-1">
                        <p data-aos="fade-right" className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw]">
                            When I look back at what decisions I chose, I want to see myself having made the crazy decisions full of faith and without fear. Id rather try, and fail, than not try at all. (plus the way I am looking at things its going to be pretty impossible to fail).
                            <br />
                            <br />
                            On top of that, I am setting up the life I truly want to live! Which is an insane idea for me, who thought I needed to maximize suffering and sacrifice.


                        </p>
                    </div>

                    <div className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-2">
                        <img
                            data-aos="fade-left"
                            src={whitepill}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>
                </div>

                <p className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw] mt-0">
                    I used to think of having fun everyday, doing the most good for the world, and minimizing my regret of dedication to the highest power I could imagine as two entirely separate activities.
                </p>
                <div
                    className="relative w-full overflow-hidden mb-6"

                >

                    <img
                        data-aos="zoom-in"
                        src={white1}
                        alt="philosophy"
                        className="w-[100%] lg:w-[50%] mx-auto rounded mb-8"
                    />

                </div>

            </div>



            {/* ---------------------------------------------third-Section----------------------------- */}
            <div
                id="white-third-section"
                className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px] pb-24"
            >


                <p className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw] mt-0">
                    I wanted to do everything I can, and only focus on that, with no chance to not perform.
                    I was in a mindset that I needed to have a movie like Rocky Balboa, who goes through a ton of shit before things turn up.
                    I locked myself in a basement, live streaming my life, attempting to not leave before I built a small city in Bangaldesh, and Redefining Status in the luxury goods market.
                </p>

                <div className="white-third-section-video flex lg:justify-between justify-center lg:pr-[50px] lg:pl-[50px] pt-8 flex-wrap">
                    <video src={video1} className="lg:w-[39vw] w-[85vw]" controls></video>
                    <video src={video2} className="lg:w-[39vw] w-[85vw] mt-8 lg:mt-0" controls></video>
                </div>


                <div className="white-third-section-video flex lg:justify-between justify-center lg:pr-[50px] lg:pl-[50px] pt-16 flex-wrap">
                    <img src={white2} className="lg:w-[26vw] w-[85vw]" alt="ss" />
                    <img src={white3} className="lg:w-[26vw] w-[85vw]  mt-8 lg:mt-0" alt="ss" />
                    <img src={white4} className="lg:w-[26vw] w-[85vw] mt-8 lg:mt-0" alt="ss" />
                </div>


                <div className="white-third-section-video flex lg:justify-between justify-center lg:pr-[50px] lg:pl-[50px] pt-16 flex-wrap">
                    <img src={white5} className="lg:w-[26vw] w-[85vw]" alt="ss" />
                    <img src={white6} className="lg:w-[52vw] w-[85vw] mt-8 lg:mt-0" alt="ss" />
                </div>


            </div>




            {/* -----------------------------------------Forth-Section----------------------------- */}
            <div
                id="spear-fourth-section"
                className="lg:ml-[100px] lg:mr-[100px] rounded-[20px] mt-16 lg:pl-[100px] lg:pr-[100px]"
            >



                <h1 className="lg:pb-8 mt-16 lg:mt-16 text-[10vw] lg:text-[4.5vw] lg:pl-[100px] lg:pr-[100px] pl-3 pr-3 leading-[12vw] lg:leading-[5vw] text-center font-bold">
                    A Journey of Joyful Giving
                </h1>

                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[50px] lg:pr-[50px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-12">

                    Thinking if I gave my entire self to the world I could make great leaps of change was not necessarily wrong, but I learned that maximizing suffering was suboptimal.
                    <br />
                    <br />
                    It was here that I realized that maximizing my own wellbeing fell right in line with impact. Not in the sense of needing anything more than food and a place to sleep, but of having fun, and sharing that with the world. The most popular live streamers of the world, are like adult children.
                    <br />
                    <br />
                    In fact I realize I need to be more child-like, we all do.
                    <br />
                    <br />

                    If I believed in chakras this sort of idea I think would mean they are just all in alignment on radical actions to enter storylines that end up being not only massively beneficial to the world, but massively entertaining to live in.

                </p>

                <img
                    data-aos="zoom-in"
                    src={white7}
                    alt="philosophy"
                    className="w-[100%] rounded mb-16"
                />

                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[50px] lg:pr-[50px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-12">
                    <b className="text-slate-600 lg:text-[2vw]">
                        I knew that what I had started had all the right pieces of being correct… So I updated my chart realizing they could overlap with a much bigger story.
                    </b>

                </p>

                <img
                    data-aos="zoom-in"
                    src={white8}
                    alt="philosophy"
                    className="lg:w-[70%] w-[100%] rounded mb-16  mx-auto"
                />


                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[50px] lg:pr-[50px] pl-3 pr-3 text-slate-500 lg:leading-[1.8vw] text-center pb-12">
                    I am very glad to see more people talking about the necessity of positive stories in influencing the world.

                    Yet Optimism is nothing without action.
                    <br />
                    <br />
                    So it’s time we arrive all the way into the meat and potatoes, the works and the feats brought to life by this soup of potential.

                    Its not a fairytale in the sense that it isn’t a fictional story, and it is a fairytale in the sense that I would consider it magical.

                    <br />
                    <br />

                    Standing alone these ideas POWERFUL and may work. And by making them content in the bigger narrative of the continued attempt, they, and us, become unstoppable. It helps us build the only eternal moat that exists in this life, the story of us and who we were in our spiritual infancy.

                </p>


                <img
                    data-aos="zoom-in"
                    src={white9}
                    alt="philosophy"
                    className="w-[100%] rounded mb-16  mx-auto"
                />
            </div>

            {/* ---------------------------------------------Fifth-Section----------------------------- */}
            <div
                id="second-section"
                className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px]"
            >
                <div className="section-container flex gap-10 flex-wrap lg:justify-between justify-center pt-24 pb-16 lg:pt-24">
                    <div className="flex lg:w-[45vw] w-[90vw] text-part items-center order-2 lg:order-1">
                        <p className="lg:p-10 p-4 text-[4.5vw] lg:text-[1.3vw] text-slate-400 lg:leading-[1.9vw]">
                            It’s uplifted by building an insanely good execution engine, which exists in its infancy for me today allowing me to put a Billionaire and many millionaires support behind me. With plans for it to grow from campus to campus finding the Elon’s of the world.
                            <br />
                            <br />
                            Telling the story of that, and kickstarting their story building upon the spear that does what Effective Altruism failed to do.
                            <br />
                            <br />
                            (Because understand systems thinking unlike that crowd.)
                        </p>
                    </div>

                    <div className="flex justify-center six-section-img lg:flex-nowrap h-auto lg:w-[37vw] w-[80vw] order-1 lg:order-2">
                        <img

                            src={white11}
                            alt="Description"
                            className="w-[100%] border-radius rounded-[40px]"
                        />
                    </div>


                </div>


                <img
                    data-aos="zoom-in"
                    src={white10}
                    alt="philosophy"
                    className="w-[100%] rounded mb-16 mt-8  mx-auto"
                />

                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[50px] lg:pr-[50px] pl-3 pr-3 text-slate-400 lg:leading-[1.8vw] text-center pb-16">
                    Their billions have gone to waste with a failure to think bigger and dream higher for humanity. They failed to tell the story and work on systemic global coordination mechanisms.
                    <br /><br />
                    Here’s the adventures we will go on, and build in public so the flywheel effect may go wide.
                    <br /><br />
                    ENTER: <b>MEAT AND POTATOES

                        We just went over the WHY and some of the reason why the HOW of telling the story of the ecosystem of “Team Hero” works. Now we will get into some of the works which will platform this team, and platform the dream. For coordination to manifest, it needs to come around something. The coordinating effect that we have the opportunity for will happen from multiple faculties, and what we will do with that is locate, and solve humanities most important problems.</b>

                </p>
                <Example />
            </div>



            {/* -----------------------------------------Sixth_section------------------------------ */}

            <div className="seven-section pb-10">





                <h1 className="pt-16 lg:pt-16 text-[10vw] lg:text-[4vw] lg:pl-[250px] lg:pr-[250px] pl-3 pr-3 leading-[12vw] lg:leading-[5.5vw] text-center font-bold">
                    Projects
                </h1>


                <p className="mt-6 text-[4.5vw] lg:text-[1.3vw] lg:pl-[370px] lg:pr-[370px] pl-3 pr-3 text-slate-400 lg:leading-[1.8vw] text-center">
                    <b className="underline"><a href="https://www.purplerock.xyz/part1">PURPLEROCk - THE INEVITABLE AGENDA</a></b>

                    <br />
                    <br />
                    Welcome to Purplepill - Part 1. Explore the global agenda, manifesto, positivity, future, values, mission, leadership, and empowerment.
                    <br />
                    <br />
                    <br />
                    <br />

                    Of course, this is an incomplete set of “needs” that humanity will need through this phase shift. They are legit stepping stones to help platform greatness culturally, and individually or a world where cheap dopamine will get easier and easier to grasp, and stronger mechanisms of engagement and enslavement will be pushed out be unenlightened actor, acting in the evil spirit of conquer and accumulation.
                    <br />
                    <br />
                    Infrastructure for proof of humanity, and governance will need fleshing out. What matters to me is building a community of supporters to support those working on their life’s greatest work, rather than falling trap to the old school paradigm of success.
                    <br />
                    <br />
                    And the project that this very text represents is one such onboarding to a vision of optimism and plans that I invite you to be a part of. The ultimate goal of this writing is making it good enough to be shared with everyone you know. And if you find it not to be worthy of such, I invite you to duplicate it and make it so. To give this as a gift of connection and a platform to pull people away from distraction and look into the heart of the world we are a part of.
                    <br />
                    <br />
                    Thank you for taking your time to read this whole thing, I invite you to a Whatsapp group where like-minded members may grow in spirit around these principles and ideas. The group chat description includes more about getting involved in whatever heroic way that you see fit.
                    <br />
                    <br />
                    <br />
                    <br />
                    <b className="underline"><a href="https://chat.whatsapp.com/LQlXEHLdzr3GTtYRj4nYf9">TIP OF THE SPEAR RITUAL</a></b>

                    <br />
                    <br />
                    WhatsApp Group Invite
                    <br />
                    <br />
                    <br />
                    <br />

                    I find that depth of thought can be difficult to embody without deeply emotional works of art to combine with them. These videos are magical and beautiful in that regard, and may help letting the body embody the download that just transferred.
                </p>
                <div className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px] pt-16">
                    <div
                        className="relative w-full overflow-hidden mb-10 "
                        style={{ paddingTop: "56.25%" }}
                    >
                        <iframe
                            data-aos="zoom-in" data-aos-duration="2000"
                            className="absolute top-0 left-0 w-full h-full"
                            src="https://www.youtube.com/embed/6MoGL2H3SFY?si=nZhzCEneGo2VadYQ"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
                <div className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px] pt-6">
                    <div
                        className="relative w-full overflow-hidden mb-10 "
                        style={{ paddingTop: "56.25%" }}
                    >
                        <iframe
                            data-aos="zoom-in"
                            className="absolute top-0 left-0 w-full h-full"
                            src="https://www.youtube.com/embed/Bbwp4PbWYzw?si=5IyUwd87EST4tZ0R"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>

                <div className="pr-3 pl-3 lg:pr-[100px] lg:pl-[100px] pt-6 pb-24">
                    <div
                        className="relative w-full overflow-hidden mb-10 "
                        style={{ paddingTop: "56.25%" }}
                    >
                        <iframe
                            data-aos="zoom-in"
                            className="absolute top-0 left-0 w-full h-full"
                            src="https://www.youtube.com/embed/vh7JhXiqpiI?si=mVMRdF_vcfW9mcd9"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Whitepill;
