
import "../assets/css/header.css";


function Header({ logo }) {
    return (
        <>
            <div id="header" className="w-screen">
                <div className="flex justify-center items-center min-h-[50px] lg:min-h-[100px] pt-12 pb-3">
                    <img src={logo} alt="Description" className="h-auto lg:w-[17vw] w-[40vw]" />
                </div>

            </div>
        </>
    )
}


export default Header;